/* eslint arrow-body-style: 0 */
import { frontendURL } from '../../../helper/URLHelper';
const CampaignWhatsappView = () => import('./CampaignsWhatsappView.vue');
const OneCampaignWhatsappView = () => import('./OneCampaignWhatsapp.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/campaignWhatsapp'),
      name: 'campaign-whatsapp-view',
      roles: ['administrator', 'agent'],
      component: CampaignWhatsappView,
      props: () => {
        return { inboxId: 0 };
      },
    },
    {
      path: frontendURL('accounts/:accountId/campaignWhatsapp/:campaign_id'),
      name: 'one-campaign-whatsapp-view',
      roles: ['administrator', 'agent'],
      component: OneCampaignWhatsappView,
      props: route => {
        return { inboxId: 0, campaignId: route.params.campaign_id };
      },
    }
  ],
};

<template>
  <div class="">
    <button
      class="text-[#9A9CAE] dark:text-[#9A9CAE] py-[10px] mb-[10px] flex items-center gap-[10px] hover:text-white relative transition-all px-0"
      :class="{
        'text-white':
          isNotificationPanelActive,
      }"
      @click="openNotificationPanel"
    >
      <fluent-icon
        icon="alert"
        class="text-[#464852]"
        size="16"
        :class="{
          'text-white': isNotificationPanelActive,
        }"
      />
      <p class="text-[13px] font-[500] mb-0" :class="{
          'text-white': isNotificationPanelActive,
        }">{{ $t('INBOX_MGMT.NOTIFACTIONS') }}</p>
      <span
        v-if="unreadCount"
        class="text-black-900 bg-yellow-300 absolute -top-0.5 -right-1 text-xxs min-w-[1rem] rounded-full"
      >
        {{ unreadCount }}
      </span>
    </button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
      notificationMetadata: 'notifications/getMeta',
    }),
    unreadCount() {
      if (!this.notificationMetadata.unreadCount) {
        return '';
      }

      return this.notificationMetadata.unreadCount < 100
        ? `${this.notificationMetadata.unreadCount}`
        : '99+';
    },
    isNotificationPanelActive() {
      return this.$route.name === 'notifications_index';
    },
  },
  methods: {
    openNotificationPanel() {
      if (this.$route.name !== 'notifications_index') {
        this.$emit('open-notification-panel');
      }
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('button',{staticClass:"text-[#9A9CAE] dark:text-[#9A9CAE] py-[10px] mb-[10px] flex items-center gap-[10px] hover:text-white relative transition-all px-0",class:{
      'text-white':
        _vm.isNotificationPanelActive,
    },on:{"click":_vm.openNotificationPanel}},[_c('fluent-icon',{staticClass:"text-[#464852]",class:{
        'text-white': _vm.isNotificationPanelActive,
      },attrs:{"icon":"alert","size":"16"}}),_vm._v(" "),_c('p',{staticClass:"text-[13px] font-[500] mb-0",class:{
        'text-white': _vm.isNotificationPanelActive,
      }},[_vm._v(_vm._s(_vm.$t('INBOX_MGMT.NOTIFACTIONS')))]),_vm._v(" "),(_vm.unreadCount)?_c('span',{staticClass:"text-black-900 bg-yellow-300 absolute -top-0.5 -right-1 text-xxs min-w-[1rem] rounded-full"},[_vm._v("\n      "+_vm._s(_vm.unreadCount)+"\n    ")]):_vm._e()],1)])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
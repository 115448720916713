<template>
  <div
    class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0"
  >
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.TELEGRAM_CHANNEL.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.TELEGRAM_CHANNEL.DESC')"
    />
    <form class="mx-0 flex flex-wrap" @submit.prevent="createChannel()">
      <div class="w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%]">
        <label :class="{ error: $v.botToken.$error }">
          {{ $t('INBOX_MGMT.ADD.TELEGRAM_CHANNEL.BOT_TOKEN.LABEL') }}
          <input
            v-model.trim="botToken"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.TELEGRAM_CHANNEL.BOT_TOKEN.PLACEHOLDER')
            "
            @blur="$v.botToken.$touch"
          />
        </label>
        <p class="help-text">
          {{ $t('INBOX_MGMT.ADD.TELEGRAM_CHANNEL.BOT_TOKEN.SUBTITLE') }}
        </p>
        <p class="help-text ">
          BotFather هو الروبوت الوحيد الذي يحكمهم جميعًا. استخدمه لإنشاء حسابات روبوت جديدة وإدارة برامج الروبوت الموجودة لديك
        </p>
        <p class="text-[black] " >
          <span>1- </span> 
          <span>لتوجه الى BotFather </span>
          <a href="https://t.me/BotFather" class=" underline text-[#369EFF]" target="_blank">اضغط هنا</a>
        </p>
        <p class="text-[black] " > 
          <span>2- </span> 
          <span>ثم ابدا المحادثه ب </span>
          <span class="cursor-pointer text-[#369EFF]" @click="copyText"> /newbot </span>
        </p>
        <p class="text-[black] " > 
          <span>3- </span> 
          <span>ثم اتبع التعليمات مثل ادخال اسم الروبوت </span>
        </p>

        <p class="text-[black]  " > 
          <span>4- </span> 
          <span>ثم ادخال اسم المستخدم للربوت يجب انا يكون هذا الاسم ينتهى بكلمة bot وبين كل كلمة والاخرى ( _ ) مثال : <span class="text-[#369EFF]">examble_bot</span></span>
        </p>

        <p class="text-[black] " > 
          <span>5- </span> 
          <span>عندما يكون اسم المستخدم صالح للاستخدام سوف  يتم نشئ token </span>
        </p>
        
      </div>

      <div class="w-full">
        <woot-submit-button
          :loading="uiFlags.isCreating"
          :button-text="$t('INBOX_MGMT.ADD.TELEGRAM_CHANNEL.SUBMIT_BUTTON')"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader.vue';

export default {
  components: {
    PageHeader,
  },
  mixins: [alertMixin],
  data() {
    return {
      botToken: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
  },
  validations: {
    botToken: { required },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const telegramChannel = await this.$store.dispatch(
          'inboxes/createChannel',
          {
            channel: {
              type: 'telegram',
              bot_token: this.botToken,
            },
          }
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: telegramChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(
          this.$t('INBOX_MGMT.ADD.TELEGRAM_CHANNEL.API.ERROR_MESSAGE')
        );
      }
    },

    copyText(){
      const text= '/newbot'
      navigator.clipboard.writeText(text).then(
        () =>{
          this.showAlert('تم نسخ الكلمة بنجاح');
        },
        (err) => {
          console.error("Failed to copy text: ", err);
        }
      )
    }
  },
};
</script>

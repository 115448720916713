var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{attrs:{"to":_vm.to,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ href, isActive, navigate }){return [_c('a',{staticClass:"text-[#9A9CAE] dark:text-[#9A9CAE] py-[10px] mb-[10px] flex items-center gap-[10px] hover:text-white relative transition-all",class:{
      'text-white':
        isActive || _vm.isChildMenuActive,
    },attrs:{"title":_vm.$t(`SIDEBAR.${_vm.name}`),"href":href,"rel":_vm.openInNewPage ? 'noopener noreferrer nofollow' : undefined,"target":_vm.openInNewPage ? '_blank' : undefined},on:{"click":navigate}},[_c('fluent-icon',{staticClass:"text-[#464852]",class:{
        'text-white': isActive || _vm.isChildMenuActive,
      },attrs:{"icon":_vm.icon,"size":"16"}}),_vm._v(" "),_c('p',{staticClass:"text-[13px] font-[500] mb-0",class:{
        'text-white': isActive || _vm.isChildMenuActive,
      }},[_vm._v(_vm._s(_vm.$t(`SIDEBAR.${_vm.name}`)))]),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.name))]),_vm._v(" "),(_vm.count)?_c('span',{staticClass:"text-black-900 bg-yellow-500 absolute -top-1 -right-1"},[_vm._v("\n      "+_vm._s(_vm.count)+"\n    ")]):_vm._e()],1)]}}])})
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
<template>
  <div class="w-[34px] h-[34px]">
    <router-link :to="dashboardPath" replace class="flex gap-[10px] items-center">
      <img src="../../../assets/images/iconLogo.png" alt="Automate" />
      <h1 class="text-white dark:text-white font-[500] text-[25px]">{{ $t('INBOX_MGMT.PROJECT_NAME') }}</h1>
    </router-link>
  </div>
</template>
<script>
import { frontendURL } from 'dashboard/helper/URLHelper';

export default {
  props: {
    source: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    dashboardPath() {
      return frontendURL(`accounts/${this.accountId}/dashboard`);
    },
  },
};
</script>

import { frontendURL } from '../../../helper/URLHelper';
const Index = () => import('./Index.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/ai'),
      roles: ['administrator', 'agent'],
      component: Index,
      name: 'ai_index',
      props: () => {
        return { inboxId: 0 };
      }
    },
  ],
};
